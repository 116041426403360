import { getPois } from "@/helpers/apiHelper";
import {
  getSettings,
  fixFeaturesAndCategoriesForQuery
} from "@/helpers/commonHelper";
import { checkStep } from "@/helpers/detailViewHelper";
import Card from "@/components/Card/Card.vue";
import TeaserCard from "@/components/TeaserCard/TeaserCard.vue";
import "@/App.sass";
import "./Teaser.sass";

export default {
  name: "teaser",
  components: { TeaserCard, Card },
  props: {
    id: {
      type: String,
      default: "eopoi-teaser"
    },
    listUri: {
      type: String,
      required: true
    },
    targetUri: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      default: "de-DE"
    },
    apiUrl: {
      type: String,
      required: true
    },
    apiKey: {
      type: String,
      required: true
    },
    initialSettings: Object,
    limit: {
      type: Number,
      default: 3
    },
    location: String,
    featureIds: Array,
    categoryIds: Array,
    highlight: Boolean,
    sorting: String,
    textColor: {
      type: String,
      default: "#333"
    }
  },
  data: () => ({
    isLoading: true, // initial load?
    widgetWidth: 0, // width of complete element
    pois: [], // list of pois
    settings: {},
    subTitleInsteadOfDescription: false,
    error: ""
  }),
  computed: {
    isMobile() {
      return this.widgetWidth < 600; // => https://vuetifyjs.com/de-DE/customization/breakpoints/
    },
    cols() {
      // calculate ideal columns
      const ideal = Math.floor(12 / (this.pois && this.pois.length) || 1);

      if (this.widgetWidth >= 1900) return Math.max(ideal, 2);
      if (this.widgetWidth >= 1260) return Math.max(ideal, 3);
      if (this.widgetWidth >= 960) return Math.max(ideal, 4);
      if (this.widgetWidth >= 600) return Math.max(ideal, 6);
      return 12;
    },
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    categoryTypes() {
      return this.$store.state.categoryTypes;
    }
  },
  async mounted() {
    this.$store.commit("SET_SETTINGS", { apiKey: this.apiKey });
    this.widgetWidth = this.$refs.theWidget.offsetWidth;
    await Promise.all([this.getDataFromStore()]);
  },
  async created() {
    // set locale globally
    this.$vuetify.lang.current = this.locale;

    // if no settings => get config from API!
    if (this.initialSettings) {
      this.settings = this.initialSettings;
    } else {
      await this.getSettings();
    }

    // load matching pois
    await this.loadData();

    // loading finished
    this.isLoading = false;

    // display subTitle instead of description
    this.subTitleInsteadOfDescription =
      this.settings.apiKey.subTitleInsteadOfDescription ||
      this.subTitleInsteadOfDescription;
  },
  methods: {
    createQuery() {
      // define query
      const query = {
        extended: true,
        limit: this.settings.apiKey.hitsPerPage,
        offset: this.settings.apiKey.startOffset,
        active: this.settings.apiKey.showReleased,
        hidden: 0,
        highlight: this.settings.apiKey.showHighlight,
        highlight_to_top: this.settings.apiKey.showHighlightToTop,
        sort:
          this.$store.state.sort ||
          this.settings.apiKey?.defaultSorting ||
          "sortTitle",
        direction:
          this.$store.state.direction ||
          this.settings.apiKey?.defaultDirection ||
          "asc"
      };

      fixFeaturesAndCategoriesForQuery(query, this.settings);

      return query;
    },
    async loadData() {
      const query = this.createQuery();

      try {
        const data = await getPois(this.apiUrl, this.apiKey, query);

        this.pois = (data && data.data) || [];
      } catch (e) {
        this.error = e;
      }
    },
    async getDataFromStore() {
      await this.$store.dispatch("getCategories");
      await this.$store.dispatch("getFeatures");

      await this.$store.dispatch("getCategoryGroups");
      await this.$store.dispatch("getCategoryTypes");
      await this.$store.dispatch("getCities");
    },
    async getSettings() {
      await getSettings.bind(this).call();
    },
    onResize() {
      this.widgetWidth = this.$refs.theWidget.offsetWidth;
    },
    checkStep(step, poi) {
      return checkStep(step, poi, this.categoryGroups, this.categoryTypes);
    }
  }
};
