import PoiPicture from "@/components/PoiPicture/PoiPicture.vue";
import { getLocation, getSeasonOrAnyDescription } from "@/helpers/commonHelper";
import PoiOpenClosed from "@/components/PoiOpenClosed/PoiOpenClosed.vue";
import "./TeaserCard.sass";
import { apiString } from "@/helpers/detailViewHelper";

export default {
  name: "TeaserCard",
  components: { PoiOpenClosed, PoiPicture },
  props: {
    poi: {
      type: Object,
      required: true
    },
    settings: {
      type: Object,
      required: true
    },
    targetUri: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    textColor: {
      type: String,
      default: "#333"
    },
    subTitleInsteadOfDescription: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    description() {
      // currently winter and also show winter season (both settings)
      const isWinter = this.currentSeason === "winter";
      return getSeasonOrAnyDescription(this.poi, isWinter, true, true);
    },
    location() {
      return getLocation(this.poi?.address, this.settings?.apiKey);
    },
    apiString,
    href() {
      if (this.$store.state.targetUri) {
        // replace place holder strings with meaningful stuff
        return this.$store.state.targetUri.replace(
          /xxxSLUGxxx/,
          (this.poi.slug || this.poi._id) + this.apiString
        );
      }
      return "";
    },
    apiKeyObj() {
      return this.settings?.apiKey ?? {};
    },
    currentSeason() {
      if (!this.apiKeyObj?.season)
        return this.$store.state.isWinter ? "winter" : "summer";
      if (this.apiKeyObj.season === "principalSeason")
        return this.settings.currentSeason;
      return this.apiKeyObj.season;
    }
  }
};
